import LayoutConfigTypes from "@/core/config/LayoutConfigTypes";

function getIcon(): string {
  if (window.location.hostname.includes("emin")) {
    return "https://hayaid-emin-dev-web-idare-frontend.s3.eu-west-1.amazonaws.com/media/logos/emin-idare-logo-dark.png";
  }
  return "https://hayaid-wefa-web-idare-frontend.s3.eu-west-1.amazonaws.com/logo.png";
}

const config: LayoutConfigTypes = {
  themeName: "Metronic",
  themeVersion: "8.0.38",
  demo: "demo1",
  main: {
    type: "default",
    primaryColor: "#009EF7",
    logo: {
      dark: getIcon(),
      light: getIcon(),
    },
  },
  illustrations: {
    set: "sketchy-1",
  },
  loader: {
    logo: getIcon(),
    display: true,
    type: "default",
  },
  scrollTop: {
    display: true,
  },
  header: {
    display: true,
    menuIcon: "font",
    width: "fluid",
    fixed: {
      desktop: true,
      tabletAndMobile: true,
    },
  },
  toolbar: {
    display: true,
    width: "fluid",
    fixed: {
      desktop: true,
      tabletAndMobile: true,
    },
  },
  aside: {
    display: true,
    theme: "dark",
    fixed: true,
    menuIcon: "svg",
    minimized: false,
    minimize: true,
    hoverable: true,
  },
  content: {
    width: "fixed",
  },
  footer: {
    width: "fluid",
  },
};

export default config;
