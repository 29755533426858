import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => {
          return import("@/views/Dashboard.vue");
        },
      },
      {
        path: "/label",
        name: "label",
        component: () => import("@/views/cms/Label.vue"),
      },
      {
        path: "/user",
        name: "user",
        component: () => import("@/views/User.vue"),
      },
      {
        path: "/category",
        name: "category",
        component: () => import("@/views/cms/Category.vue"),
      },
      {
        path: "/page",
        name: "page",
        component: () => import("@/views/cms/Page.vue"),
      },
    ],
  },
  {
    path: "/don",
    redirect: "/don/intent",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/don/intent",
        name: "don-intent",
        component: () => import("@/views/don/DonIntent.vue"),
      },
      {
        path: "/don/donation",
        name: "don-donation",
        component: () => import("@/views/don/DonDonation.vue"),
      },
      {
        path: "/don/campaign",
        name: "don-campaign",
        component: () => import("@/views/don/DonCampaign.vue"),
      },
      {
        path: "/don/campaignDetail",
        name: "don-campaignDetail",
        component: () => import("@/views/don/DonCampaignDetail.vue"),
      },
      {
        path: "/don/commitment",
        name: "don-commitment",
        component: () => import("@/views/don/DonCommitment.vue"),
      },
      {
        path: "/don/payment",
        name: "don-payment",
        component: () => import("@/views/don/DonPayment.vue"),
      },
      {
        path: "/don/project",
        name: "don-project",
        component: () => import("@/views/don/DonProject.vue"),
      },
      {
        path: "/don/kurban-hisseleri",
        name: "don-kurban-hisseleri",
        component: () => import("@/views/don/DonKurbanHisseleri.vue"),
      },
      {
        path: "/don/kurban-videolari",
        name: "don-kurban-videolari",
        component: () => import("@/views/don/DonKurbanVideo.vue"),
      },
      {
        path: "/don/representative",
        name: "don-representative",
        component: () => import("@/views/don/DonRepresentative.vue"),
      },
      {
        path: "/don/donationBind",
        name: "don-donationBind",
        component: () => import("@/views/don/DonDonationBind.vue"),
      },
      {
        path: "/don/donationBindWithoutProject",
        name: "don-donationBindWithoutProject",
        component: () => import("@/views/don/DonDonationBind.vue"),
      },
      {
        path: "/don/expense",
        name: "don-expense",
        component: () => import("@/views/don/DonExpense.vue"),
      },
      {
        path: "/don/sponsorship",
        name: "don-sponsorship",
        component: () => import("@/views/don/DonSponsorship.vue"),
      },
      {
        path: "/don/sponsorshipSettings",
        name: "don-sponsorshipSettings",
        component: () => import("@/views/don/DonSponsorshipSettings.vue"),
      },
      {
        path: "/don/sponsorshipCenter",
        name: "don-sponsorshipCenter",
        component: () => import("@/views/don/DonSponsorshipCenter.vue"),
      },
      {
        path: "/don/beneficiary",
        name: "don-beneficiary",
        component: () => import("@/views/don/DonBeneficiary.vue"),
      },
      {
        path: "/don/sponsorshipBeneficiary",
        name: "don-sponsorshipBeneficiary",
        component: () => import("@/views/don/DonSponsorshipBeneficary.vue"),
      },
    ],
  },
  {
    path: "/surec",
    redirect: "/surec-list",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/surec-list",
        name: "SurecList",
        component: () => import("@/views/surecyonetim/SurecList.vue"),
      },
    ],
  },
  {
    path: "/excel",
    redirect: "/excel/insert",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/excel/insert",
        name: "excelInsert",
        component: () => import("@/views/excel/InsertExcel.vue"),
      },
    ],
  },
  {
    path: "/help",
    redirect: "/help",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/help",
        name: "Help-View",
        component: () => import("@/views/help/Help.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];
const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(() => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  store.dispatch(Actions.VERIFY_AUTH, { api_token: JwtService.getToken() });

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
